var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ParticipationFormQuestion-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "得分情况" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "调查名称",
                      text: _vm.form.title,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "调查得分",
                      text: _vm.form.score,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "问卷信息" } },
                _vm._l(_vm.form.questions, function (question, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "question-wrapper" },
                    [
                      _c("div", { staticClass: "question" }, [
                        _c("span", { staticClass: "questionDes" }, [
                          _vm._v(
                            " 投票问题" +
                              _vm._s(index + 1) +
                              "、" +
                              _vm._s(question.questionDes)
                          ),
                        ]),
                      ]),
                      question.optionType !== 3
                        ? _c(
                            "div",
                            [
                              _vm._l(question.options, function (option, idx) {
                                return _c(
                                  "div",
                                  { key: idx, staticClass: "option" },
                                  [
                                    _c("span", [
                                      _vm._v("问题选项：" + _vm._s(option)),
                                    ]),
                                  ]
                                )
                              }),
                              question.supplement
                                ? _c("span", { staticClass: "option" }, [
                                    _vm._v(
                                      " 问题补充：" +
                                        _vm._s(question.supplement) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c("div", [
                            _c("span", { staticClass: "option" }, [
                              _vm._v("填空：" + _vm._s(question.supplement)),
                            ]),
                          ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }