<template>
  <div class="surveyParticipationForm-container">
     <tabs-panel :tabs="tabs" :tabRouter="false" :before="getDetail" :activeLabel.sync="activeLabel"></tabs-panel>
  </div>
</template>

<script>
import { queryURL } from './api'
import { TabsPanel } from 'components/bussiness'
import baseInfo from './infoTemplate/baseInfo'
import questions from './infoTemplate/questions'

export default {
  name: 'surveyParticipationForm',
  components: {
    TabsPanel
  },
  data () {
    return {
      commonProps: {
        resultData: {}
      },
      activeLabel: '基础信息',
      tabs: []
    }
  },
  created () {
    this.tabs = [{
      label: '基础信息',
      component: baseInfo,
      props: this.commonProps
    }, {
      label: '问题信息',
      component: questions,
      props: this.commonProps
    }]
  },
  methods: {
    async getData () {
      const { id } = this.$route.query
      const res = await this.$axios.get(`${queryURL}?id=${id}`)
      return res
    },
    async getDetail (next) {
      const res = await this.getData()
      this.commonProps.resultData = res.data
      next()
    }
  }
}
</script>
