<template>
  <div class="ParticipationFormQuestion-container">
    <form-panel ref="formPanel" v-bind="submitConfig" :form="form" :submitBefore="submitBefore">
      <col2-detail>
        <col2-block title="得分情况">
          <col2-item :span="24" label="调查名称" :text="form.title"></col2-item>
          <col2-item :span="24" label="调查得分" :text="form.score"></col2-item>
        </col2-block>
      </col2-detail>
      <col2-detail>
        <col2-block title="问卷信息">
          <div class="question-wrapper" v-for="(question, index) in form.questions" :key="index">
            <div class="question">
              <span class="questionDes"> 投票问题{{ index + 1}}、{{ question.questionDes }}</span>
            </div>
            <div v-if="question.optionType !== 3">
              <div class="option" v-for="(option,idx) in question.options" :key="idx">
                <span>问题选项：{{ option }}</span>
              </div>
              <span class="option" v-if="question.supplement"> 问题补充：{{ question.supplement }} </span>
            </div>
            <div v-else>
              <span class="option">填空：{{ question.supplement}}</span>
            </div>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { saveURL } from './../api'
import { Col2Detail, Col2Block, Col2Item } from 'components/bussiness'

export default {
  name: 'ParticipationFormQuestion',
  components: {
    Col2Detail, Col2Block, Col2Item
  },
  props: {
    resultData: {}
  },
  data () {
    return {
      form: {
        id: undefined,
        title: '',
        status: undefined,
        score: 0,
        questions: []
      },
      submitConfig: {
        submitUrl: saveURL,
        submitMethod: 'PUT',
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    }
  },
  created () {
    this.getQuestionInfo(this.resultData)
  },
  methods: {
    getQuestionInfo (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      delete data.questions
      delete data.title
      delete data.score
      return true
    },
    getStatus (value) {
      this.form.status = value
    }
  }
}
</script>

<style lang="scss" scoped>
.ParticipationFormQuestion-container{
   .question-wrapper{
    margin-left: 88px;
    width:700px;
    .question{
       display: flex;
       justify-content:space-between;
       font-size: 16px;
       color:#333333;
       font-weight: 700;
       margin:15px 0;
       .questionDes{
         flex:1;
         margin-right: 80px;
       }
    }
    .option{
      margin-bottom: 15px;
      font-size:16px;
    }
  }
}
</style>
