<template>
  <div class="participationInfo-container">
    <form-panel ref="formPanel" v-bind="submitConfig" :form="form" :submitBefore="submitBefore">
          <col2-detail>
            <col2-block title="参与人信息">
              <col2-item :span="24" label="真实姓名" :text="form.userName"></col2-item>
              <col2-item :span="24" label="手机号" :text="form.mobileNum"></col2-item>
              <col2-item :span="24" label="参与人身份" :text="userTypeMap[form.userType]"></col2-item>
              <col2-item :span="24" label="参与时间" :text="form.createTime"></col2-item>
              <col2-item :span="24" label="房号信息" :text="form.roomNumber"></col2-item>
              <col2-item :span="24" label="所属分公司" :text="form.regionName"></col2-item>
              <col2-item :span="24" label="是否中奖" :text="isLuckyDogMap[form.isLuckyDog]"></col2-item>
              <col2-item :span="24" label="奖品" :text="form.awardName"></col2-item>
            </col2-block>
          </col2-detail>
          <col2-detail>
            <col2-block title="状态">
              <el-form-item label="状态" :rules="[{ required: true, message: '请选择状态', trigger: 'change' }]" prop="status">
                <v-select v-model="form.status" :options="newStatusOps" @change="statusOnchange" :width="182" />
              </el-form-item>
            </col2-block>
          </col2-detail>
      </form-panel>
  </div>
</template>

<script>
import { saveURL } from './../api'
import { setStatusOps, userTypeMap, isLuckyDogMap } from './../map'
import { Col2Detail, Col2Block, Col2Item } from 'components/bussiness'

export default {
  name: 'participationInfo',
  components: {
    Col2Detail, Col2Block, Col2Item
  },
  props: {
    resultData: {}
  },
  data () {
    return {
      newStatusOps: setStatusOps(),
      userTypeMap: userTypeMap,
      isLuckyDogMap: isLuckyDogMap,
      form: {
        awardName: '',
        categoryName: '',
        communityName: '',
        createTime: '',
        id: '',
        isLuckyDog: undefined,
        mobileNum: '',
        regionName: '',
        roomNumber: '',
        status: undefined,
        title: '',
        userName: '',
        userType: undefined
      },
      submitConfig: {
        submitUrl: saveURL,
        submitMethod: 'PUT',
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    }
  },
  created () {
    this.getBaseInfo(this.resultData)
  },
  methods: {
    getBaseInfo (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      return true
    },
    statusOnchange (val) {
      this.$emit('contact', {
        index: 1,
        method: 'getStatus',
        data: val
      })
    }
  }
}
</script>
