var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "participationInfo-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "参与人信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "真实姓名",
                      text: _vm.form.userName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "手机号",
                      text: _vm.form.mobileNum,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "参与人身份",
                      text: _vm.userTypeMap[_vm.form.userType],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "参与时间",
                      text: _vm.form.createTime,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "房号信息",
                      text: _vm.form.roomNumber,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "所属分公司",
                      text: _vm.form.regionName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "是否中奖",
                      text: _vm.isLuckyDogMap[_vm.form.isLuckyDog],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "奖品",
                      text: _vm.form.awardName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "状态" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择状态",
                            trigger: "change",
                          },
                        ],
                        prop: "status",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.newStatusOps, width: 182 },
                        on: { change: _vm.statusOnchange },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }